.inboxTableButtons {
  display:flex;
  gap:10px;
}
.logout {
  margin-left: auto;
  background-color: grey;
  border-color: grey;
}
.icon {
  height: 50px;
}
.combinationContainer {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
#pharmacyCprid {
  width: 300px;
}

.combinationLabel {
  flex: 1;
}
.combinationInput {
  flex: 3;
}
button[type='submit'] {
  align-self: flex-start;
}
.cpirdSubmit {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.searchSubmit {
  display: flex;
  width: 50%;
  gap: 10px;
  flex-direction: column;
}
label {
  font-size: 18px;
}
.mainView {
  padding: 15px;
}
.navBar {
  display: flex;
  padding: 15px;
  gap: 10px;
  align-items: center;
  background-color: #73c2fb;
}
.textDisplay {
  white-space: pre-wrap;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
table,
th,
td {
  border: thin solid;
  padding: 5px;
}
tr:nth-child(odd) {
  background-color: aliceblue;
}
tr:nth-child(even) {
  background-color: white;
}
