.textNav{
  display: flex;
  gap:10px;
}

.xmlFunctionButtons{
  display:flex;
  gap: 10px;
}
.accordionText{
  inline-size: inherit;
  overflow-wrap:anywhere;
}
