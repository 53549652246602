.login {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15%;
}
.title {
  display: flex;
  align-items: center;
}
form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.login button {
  color: white;
  background-color: green;
  border-color: green;
  transition: 0.2s;
}

.login button:hover {
  background-color: white;
  color: green;
}
