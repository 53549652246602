.pharmacyOption {
display:flex;
justify-content: space-between;
flex-direction: row;
align-items: center;
}
.combinationContainer {
  min-width:250px;
  width:50%;
}
.pharmacySelection {
  min-width: 500px;
  width:50%;
}
.sendMessagesContainer{
  display:flex;
  flex-direction: row;
  gap:50px;
}
.sendMessages{
  flex: 2;
  width:60%;
}
.searchPharmacy{
  flex: 1;
  background-color: #FFC55C;
  border-radius: 25px;
  width:40%;
  padding: 10px;
}
#pharmacySearchTitle{
  text-align:center;
}
.radioButtons {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  cursor: pointer;
}
.radioButton {
  cursor: pointer;
}
input {
  background-color: aliceblue;
}

.sub-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.flex-between {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

